.jobRequirment5-header-design-container-style {
    width: 140px;
}

.jobRequirment5-Requirement-info-border {
    width: 100%;
    height: 4px;
}



.jobRequirment5-editor-css {
    height: 400px;
    overflow-y: auto;
}

.jobRequirment5-editor-label {
    margin-top: 12px;
}

.jobRequirment5-fileupload-css {
    position: absolute;
    opacity: 0;
}
.jobRequirment5-loader-padding {
    padding: 3px 10px;
}