@import '../constants/colors.scss';

.text-decoration-none {
    text-decoration: none;
}

.border-radius-05rem {
    border-radius: 0.5rem;
}

.border-radius-6px {
    border-radius: 6px
}

.border-radius-10px {
    border-radius: 10px
}

.border-radius-20px {
    border-radius: 20px
}

.border-radius-24px {
    border-radius: 24px
}

.border-radius-20px {
    border-radius: 20px
}

.border-radius-07px {
    border-radius: 07px
}

.border-radius-16px {
    border-radius: 16px
}

.text-capitalize {
    text-transform: capitalize;
}

.margin-top-10px {
    margin-top: 10px;
}

.padding-left-13rem {
    padding-left: 1.3rem;
}

.height-27rem {
    height: 2.7rem;
}

.border-1px {
    border: 1px solid $primary-white !important
}

.padding-right-120px {
    padding-right: 120px;
}

.margin-top-5px {
    margin-top: 5px;
}

.boxshadow-0-12-13-rgb {
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04)
}

.height-100vh {
    height: 100vh;
}

.boder-colored-Cloud-gray {
    border-color: $Cloud-gray;
}
.cursor-pointer
{
cursor: pointer;
}