@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

//font weight 
.font-weight-600 {
    font-weight: 600;
}

.font-weight-500 {
    font-weight: 500;
}

//font size
.font-size-07rem {
    font-size: 0.7rem;
}

.font-size-08 {
    font-size: 0.8rem;
}

.font-size-09rem {
    font-size: 0.9rem
}

.font-size-11rem {
    font-size: 1.1rem
}

.font-size-15rem {
    font-size: 1.5rem
}

.font-size-13rem {
    font-size: 1.3rem
}

.font-size-12px {
    font-size: 12px;
}

.font-size-14px {
    font-size: 14px;
}

.font-size-15px {
    font-size: 15px;
}

.font-size-14rem {
    font-size: 1.4rem;
}


.font-size-1rem {
    font-size: 1rem;
}

.font-size-2rem {
    font-size: 2rem;
}

.font-size-16px {
    font-size: 16px;
}

.font-size-18px {
    font-size: 18px;
}

.font-size-20px {
    font-size: 20px;
}

.font-size-22px {
    font-size: 22px;
}

.font-size-24px {
    font-size: 24px;
}

.font-size-26px {
    font-size: 26px
}

.font-size-30px {
    font-size: 30px;
}

.font-size-31px {
    font-size: 31px;
}

.font-size-32px {
    font-size: 32px;
}

//font family
.font-family-roboto {
    font-family: roboto
}