@import '../constants/colors.scss';

.matching-job-filter-Applied {
    width: 120px;
    height: 30px
}

.matching-job-box-shadow {
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
}

.matching-job-status {
    background: $Soft-gray;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
}

.matching-job-shortlisted {
    color: $Bright-orange;
    cursor: pointer;
}

.matching-job-shortlisted-value {
    color: $Bright-orange;

}

.matching-job-shortlisted-value-two {
    color: $primary-black;

}

.matching-job-reject-span {
    background: rgba(196, 196, 196, 0.2);
    border: 1px solid $primary-white,
}

.matching-job-box-shadow-with-margin {
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
    // margin-top: 1px
}

.matching-job-content-loader-padding {
    padding-bottom: 11%;
}

.matching-job-list-Data {
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
    margin-top: 1px;
}

.matching-job-content-uuid-Data {
    font-size: 24px;
    display: none;
}

.matching-job-content-uuid-Data-Or {
    display: none;
}

.matching-job-word-Wrap-and-white-space {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.matching-job-content-status {
    width: 81px;
    height: 31px;
    padding: 3px 10px;
}

.matching-job-content-status:hover {
    color: $primary-white
}

.matching-job-margin-left-17rem {
    margin-left: -1.7rem;
}

@media (max-width:1000px) {
    .matching-job-margin-left-17rem {
        margin-left: 0;
    }


}

@media (max-width:540px) {
    .step-arrow {
        color: #1e1e1e;
        padding: 15px 16px;
        position: relative;
        font-size: 0.6rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid black;
    }
}