@import 'colors';

.colored-muted-purple {
    color: $muted-purple
}

.color-dark-gray {
    color: $dark-gray
}

.colored-neutral-gray {
    color: $neutral-gray;
}

.colored-primary-white {
    color: $primary-white;
}

.colored-Purple-Indigo {
    color: $Purple-Indigo
}

.colored-Slate-gray {
    color: $Slate-gray
}

.colored-Pale-silver {
    color: $Pale-silver
}

.colored-Graphite-gray {
    color: $Graphite-gray
}

.colored-Deep-violet {
    color: $Deep-violet
}

.colored-Lavender-slate {
    color: $Lavender-slate;
}

.colored-Royal-blue {
    color: $Royal-blue
}

.colored-Deep-charcoal {
    color: $Deep-charcoal
}

.colored-Pale-taupe {
    color: $Pale-taupe
}

.colored-lavender-gray {
    color: $lavender-gray
}

.colored-Bright-red {
    color: $Bright-red;

}

.colored-Slate-gray {
    color: $Slate-gray
}

.colored-Bright-orange {
    color: $Bright-orange
}

.colored-primary-black {
    color: $primary-black
}

.colored-Royal-purple {
    color: $Royal-purple !important;
}

.colored-charcoal-gray {
    color: $charcoal-gray
}

.colored-Ash-gray {
    color: $Ash-gray;
}

.colored-sky-blue {
    color: $sky-blue;
}

.colored-Silver-gray {
    color: $Silver-gray;
}

.colored-Flame-red {
    color: $Flame-red
}

.colored-Pale-taupe {
    color: $Pale-taupe
}

.colored-Jet-gray {
    color: $Jet-gray;
}

.colored-Steel-gray {
    color: $Steel-gray
}

.colored-Vibrant-orange {
    color: $Vibrant-orange
}

.colored-Forest-green {
    color: $Forest-green
}

.colored-Mint-green {
    color: $Mint-green
}

.colored-Deep-slate {
    color: $Deep-slate
}

.colored-Sunset-orange {
    color: $Sunset-orange
}

.colored-Granite-gray {
    color: $Granite-gray
}

.colored-Silver-mist {
    color: $Silver-mist
}

.colored-Pale-steel {
    color: $Pale-steel
}

.colored-Misty-gray {
    color: $Misty-gray
}

.colored-Soft-lavender {
    color: $Soft-lavender
}

.colored-Midnight-blue {
    color: $Midnight-blue
}

//background colored
.background-colored-primary-white {
    background: $primary-white;
}

.background-colored-Lavender-mist {
    background: $Lavender-mist;
}

.background-colored-Pale-gray {
    background: $Pale-gray;
}

.background-colored-Lavender-slate {
    background: $Lavender-slate;
}

.background-colored-Deep-violet {
    background: $Deep-violet;
}

.background-colored-sky-blue {
    background: $sky-blue;
}

.background-colored-Mint-green {
    background: $Mint-green;
}

.background-colored-Fiery-red {
    background: $Fiery-red;
}

.background-colored-Sunset-orange {
    background: $Sunset-orange;
}

.background-colored-Pale-sky-blue {
    background: $Pale-sky-blue;
}

.background-colored-Soft-gray {
    background: $Soft-gray;
}

.background-colored-Cerulean-blue {
    background: $Cerulean-blue
}

.background-colored-Electric-blue {
    background: $Electric-blue
}

.background-colored-Deep-sky-blue {
    background: $Deep-sky-blue
}

.background-colored-Silver-gray {
    background: $Silver-gray
}

.background-colored-Crimson-red {
    background: $Crimson-red
}

.background-colored-Soft-lavender {
    background: $Soft-lavender
}

.background-colored-Skyline-blue {
    background: $Skyline-blue
}

.background-colored-Ocean-blue {
    background: $Ocean-blue;
}