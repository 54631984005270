.candidate-Dashbaordview-Rating-css {
    background: rgba(255, 138, 0, 0.2);
    border-radius: 8px;
}

.candidate-Dashbaordview-email-text-css {
    word-break: break-all;
    text-align: justify;
    margin-right: 42px;
    margin-left: 50px;
}

.candidate-Dashbaordview-information-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.candidate-Dashbaordview-calender-iconcss {
    transform: scale(0.8);
}