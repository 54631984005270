@import 'constants/colors.scss';

.circular-border-radius {
    border-radius: 20px
}

.dashboardResumeTitle {
    text-overflow: "ellipsis";
    overflow: "hidden";
    white-space: "pre-wrap";
    word-wrap: "break-word";
}

.dashboard-information-mobile {
    background-color: $Lavender-mist;
    border-radius: "10px";
}