.userRegistrationForm-ssn-css {
    position: absolute;
    left: 40px;
    top: 44px;
}

.userRegistrationForm-ssn-paddingleft-css {
    padding-left: 74px
}

.userRegistrationForm-loader-padding-css {
    padding: 3px 5px;
}