.profile-browse-css {
    opacity: 0;
    position: absolute;
}

.profile-logo-image-css {
    transform: scale(0.8);
}

.profile-loader-icon-padding {
    padding: 5px 5px;
}