@import'../constants/colors.scss';

.applictions-box-shadow {
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
}

.applictions-box-shadow-reverse {
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
}

.applictions-box-shadow-two {
    background: $Soft-gray;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.applictions-box-span-css {
    background: rgba(196, 196, 196, 0.2);
    border: 1px solid $primary-white;
}

.applications-margin-top {
    margin-top: 1px;
}