@import '../constants/colors.scss';

.navBar-TopBar-dashboard-text {
    display: inline-block;
}

.navBar-TopBar-bell-icon {
    background-color: $Lavender-blue;
    padding: 16px 16px;
    border-radius: 50%
}

.navBar-TopBar-fa-icon {
    padding: 13px 10px;
    color: $sky-blue;
}

.navBar-TopBar-msg-label {
    font-size: 15px;
    padding: 10px 10px 10px 0px;
    // }
}

// .navBar-TopBar-user-profile-css {
//     cursor: pointer;
//     border-radius: 50%;
//     width: 60px;
//     height: 60px;
// }

// .navBar-TopBar-circle-First-Last-Name {
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
//     width: 107px;
// }

.drawer-logo {
    width: 100%;
    max-width: 150px;
    /* Adjust as needed */
}

// .MuiDrawer-paper {
//     transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1);
// }

// .MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
//     width: 240px;
// }

// .MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft.MuiDrawer-paperAnchorDockedLeft {
//     width: 60px;
// }

// .MuiMenu-paper {
//     min-width: 240px;
//     /* Adjust as needed */
// }
/* Main styles for the drawer */
// .drawer {
//     width: 240px;
//     /* Default width for expanded drawer */
//     background-color: #3D007A;
//     /* Sidebar background color */
//     color: white;
//     height: 100%;
//     transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1);
// }

// /* Styles for the collapsed drawer */
// .drawer-collapsed {
//     width: 60px;
//     /* Width when collapsed */
// }

// /* Main styles for the hamburger button */
// .hamburger-button {
//     position: absolute;
//     top: 16px;
//     z-index: 1201;
//     color: white;
//     transition: right 225ms cubic-bezier(0.4, 0, 0.6, 1);
// }

// /* Styles for the hamburger button when the drawer is expanded */
// .hamburger-button-expanded {
//     right: 12px;
//     /* Position when drawer is expanded */
// }

// /* Styles for the hamburger button when the drawer is collapsed */
// .hamburger-button-collapsed {
//     right: 12px;
//     /* Position when drawer is collapsed */
// }

// /* Mobile-specific styles */
// @media (max-width: 600px) {
//     .hamburger-button-mobile {
//         position: absolute;
//         top: 16px;
//         left: 16px;
//         z-index: 1201;
//         color: white;
//         display: block;
//     }

//     .drawer-mobile {
//         width: 240px;
//         /* Default width for mobile drawer */
//     }
// }
@media(max-width:767px) {
    .mobile-hidden {
        display: none;
    }

    // .navBar-TopBar-dashboard-text {
    //     display: inline-block;
    //     margin-left: 30%;
    // }
}

@media(max-width:1093px) {
    #postjobid {
        display: inline-block;
        /* display: none; */
        position: absolute;
        top: -30%;
        left: 48%;
    }
}