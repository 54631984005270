.Notification-icons-css
{
    padding: 0px 0px 10px 10px;
    color: rgb(63, 154, 224);
    font-size: 23px;
}
.Notification-message-css
{
    padding: 10px 10px 10px 0px

}