@import '../constants/colors.scss';

.JobRequirementPreview-container-box-shadow {
    border-Radius: 10px;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-Color: $primary-white;
}
.JobRequirementPreview-editor
{
     height: 400px;
     overflow-y: auto;
}
.JobRequirementPreview-browse-file-css
{
 display:none;
}
.jobRequirmentPreview-loader-padding {
    padding: 3px 10px;
}