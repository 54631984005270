.clientDashcard-container-boxShadow-css {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

}

.clientDashcard-container-progressbar-css {
    border-radius: 20px;
    width: 4px;
    height: 95%;
    margin-left: 5%;
}
.clientDashcard-container-image-css {
    border-radius: 35px;
     padding: 11px 9px 14px 9px;

}