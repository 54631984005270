@import '../constants/colors.scss';

.jobRequirment4-container-padding-bottom {
    padding-bottom: 11%;
}

.jobRequirment4-header-design-container-style {
    width: 140px;
}

.jobRequirment4-Requirement-info-border {
    width: 100%;
    height: 4px;
}

.jobRequirment4-loader-padding {
    padding: 3px 10px;
}