@import '../../constants/colors.scss';

.matchingJob-loader-container {
    padding-bottom: 11%
}

.matchingjob-cretaedAt-width {
    width: 7rem
}

.matchingjob-modal-border {
    border: 2px solid $Soft-silver
}

.matchingJob-working-type-cssText {
    border-radius: 4px;
    background-color: $Mint-whisper
}

.matchingJob-progressbar-width {
    width: 90%
}



.matchingJob-progressbar-width-two {
    width: 60%
}

.matchingJob-progressbar-width-Three {
    width: 70%
}

.matchingJob-Tabular-view-box-shadow {
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);

    margin-top: 1px;
}

.matchingJob-jobTitle-css {
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
    margin-left: -1rem;
}

.matchingJob-wordwrapAnd-wordSpace {
    word-wrap: break-word;
    white-space: pre-wrap;

}

@media (max-width: 768px) {
    .matchingJob-jobTitle-css {
        font-size: 12px;
    }

    // .matchingjobdata {
    //     font-size: 10px;
    // }

    .box {
        padding: 10px;
    }



    .pl-4 {
        padding-left: 1rem !important;
    }

    .pr-5 {
        padding-right: 1rem !important;
    }
}



@media (max-width: 576px) {
    .matchingJob-jobTitle-css {
        font-size: 10px;
    }

    // .matchingjobdata {
    //     font-size: 8px;
    // }
    .navBar-TopBar-bell-icon {
        background-color: #5E52B8;
        padding: 8px 11px;
        border-radius: 50%;
        position: absolute;
        top: 19px;
        left: 20px;
    }

    .story_shape_final {
        width: 50px;
        height: 50px;
        shape-outside: circle(50% at 50% 50%);
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        position: relative;
        margin-top: 10px;
    }

    .donothaveAccount-margin {
        margin-left: 26% !important;
    }

    .full-cover-img {
        width: 107%;
        height: 100vh;
        object-fit: cover;
        border-radius: 0 0.5rem 0.5rem 0;
    }

    .logInImgLeft {
        margin-left: -3%;
    }

    .popuptext {
        font-size: 10px;
    }

    .navbartopbarbothimageandicon {
        margin-top: -3px;
    }

    .align-items-top-custom-screen {
        align-items: flex-start !important
    }

    .full-cover {
        width: 108%;
        height: 100vh;
        object-fit: cover;
        border-radius: 0;
    }

    .signUpImageMarginLeft {
        margin-left: -4%;
    }

    .mainmenu-height-and-padding {
        border-radius: 38px 0px 0px 0px;
        height: 100vh;
        margin-left: -16px !important;
    }

    .stick {
        margin-left: -15px !important;
    }
}

@media (max-width: 1000px) {
    .matchingJob-progressbar-width {
        width: 4rem
    }

    .matchingJob-progressbar-width-two {
        width: 4rem
    }

    .matchingJob-progressbar-width-Three {
        width: 4rem
    }

    // .signupforminputtext {
    //     font-weight: 500;
    //     font-size: 0.5rem;
    //     color: #1E1E1E;
    // }
}

.matching-job-14rem {
    margin-left: -1.4rem;
}

.matching-jobcards-link:hover {
    text-decoration: none
}

.loader {
    width: 100%;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    // background: #18181b;
    // background: rgba(24, 24, 27, 0.8);
    // background: #A199CF;
    background: rgba(116, 105, 182, 0.59);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg {
    width: 100%;
}

.loading-text {
    width: 100%;
    font-weight: bold;
    color: #fff;
    transform: translate(.4rem);
    margin-top: 1rem;
}

@media (max-width:370px) {
    .navBar-TopBar-bell-icon {
        background-color: #5E52B8;
        padding: 8px 11px;
        border-radius: 50%;
        position: absolute;
        top: 19px;
        left: 1px;
    }
}