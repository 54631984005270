@import '../constants/colors.scss';

.applications-searchbar-margin-left {
    margin-left: -1.7rem
}

.applications-heading-text {
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
}

.applications-heading-text-second {
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: $primary-white;
    // margin-top: 1px;
}

.application-content-loader {
    padding-bottom: "11%"
}

.application-job-title {
    word-wrap: break-word;
    white-space: pre-wrap,
}

.application-match-percentage-margin {
    margin-top: 3%;
    margin-right: 9%;
    margin-left: -16%;
}

.application-match-percentage-circular-progressbar-width {
    width: 188%;
}

.application-content-applied-btn {


    background-color: $Lavender-slate;
    color: $primary-white;
    width: 81px;
    height: 31px;
    padding: 5px 10px 5px 10px;
}

@media (max-width:1000px) {
    .applications-searchbar-margin-left {
        margin-left: 0;
    }

}