@import 'constants/colors.scss';

body {
  font-family: Roboto;
  font-weight: bolder;
  background-color: $primary-white;
  ;
  background-color: $Soft-lavender;
  // afaaacdab7a59968bf858066bffff7f3459913a3
}

.scroll {
  position: sticky;
  top: 0;
  height: 80px !important;

  z-index: 10;
  background-color: $Soft-lavender;
}

.stick {
  position: relative;
  margin-left: 260px;
}

// .companylogo {
//   transform: scale(0.8);

// }
.companylogo {
  position: relative;
  right: -2rem;
  transform: scale(1);
  top: 0.8rem;
}

.dashboardfont {
  font-weight: 500;
  color: $primary-white !important;
}

.dashboardbtnpadding {
  padding: 0;
}

.dashboardbtnimgpadding {
  padding: 12px;
  // background-color: white;
  transform: scale(0.8);
}

.dashboardbtnimgpadding:hover {
  // padding: 3px;
  transform: scale(0.8);
}

@media (max-width:767px) {
  .dashboardbtnimgpadding {
    padding: 3px;
    transform: scale(0.5);
  }

  .dashboardbtnimgpadding:hover {
    padding: 3px;
    transform: scale(0.5);
  }
}

.sidebar {
  min-width: 275px;
  position: fixed;
  z-index: 1000;
  height: 100%;
  background-color: $primary-white;

  background-color: rgba(116, 105, 182, 0.59);

  @media only screen and (max-width: 1000px) {
    .hide {
      display: none;
    }

    min-width: 80px;
  }

}



// @media only screen and (min-width: 1000px) {
//   .hide {
//     display: block;
//   }

// }

@media only screen and (max-width: 1000px) {
  // .sidebar {
  //   display: none;
  // }

  .stick {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1245px) and (max-width:1399px) {
  .popbox {
    margin-left: -35px;
  }
}

@media only screen and (min-width: 1139px) and (max-width:1245px) {
  .popbox {
    margin-left: -35px;
  }

  .notification {
    margin-left: -60px;
  }
}

@media only screen and (min-width:799px) and (max-width:1139px) {
  .popbox {
    margin-left: -30px;
  }

  .notification {
    margin-left: -50px;
  }
}

@mixin staticdatafontmixin($weight, $style, $size) {
  font: {
    family: Poppins;
    weight: $weight;
    style: $style;
    size: $size;
  }

}

.staticdatafontheader {
  @include staticdatafontmixin(500, normal, 16px);
}

.staticdatafontusername {
  @include staticdatafontmixin(500, normal, 14px);
}

.staticdatacommonfont {
  @include staticdatafontmixin(400, normal, 12px);
}

.staticdatauser {
  width: 96.31px;
  height: 96.31px;
}

.dashboardclickedfont {
  color: $Obsidian;
  line-height: 27px;

  font: {
    weight: 600;
    style: normal;
    size: 16px;
  }

}

.dashboardbox {
  background-color: $Pale-gray;
  border-radius: 16px 0px 0px 16px
}

.staticdataposition {
  display: none;
  position: relative;
  top: 18%;
}

.clientbackground {
  background-color: $Pale-gray;
  border-radius: 16px 0px 0px 16px;
}

.postjobbtn {
  border: 1px solid $lavender-gray;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  color: $lavender-gray;
  box-shadow: none;
}

.margin-left {
  margin-left: 1rem;
}

.postjobbtn:hover {
  background-color: $lavender-gray;
  color: $primary-white;
}

.story_shape {
  width: 57px;
  height: 57px;
  -webkit-shape-outside: circle(50% at 50% 50%);
  shape-outside: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  position: relative;
}

.story_img {
  height: 100%;
  width: 100%;
  cursor: pointer;
  backface-visibility: hidden;
}

.story_caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $primary-white;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  // backface-visibility: hidden;
}

/* notification start */
.notification-card {
  border: 1px solid $Dim-Gray;
  border-radius: 10px;
  padding: 10px 1px 10px 1px;
}

.notifybadges {
  color: $primary-white;
  border-radius: 50%;
  padding: 1px 9px;
  position: absolute;
  top: 12px;
  right: 207px;
  font-size: small;
}

.center {
  position: absolute;
  top: 89%;
  left: 512px;
  font-weight: 500;
  font-size: 23px;
  color: $primary-black;
}

@media only screen and (max-width:1099px) and (min-width:768px) {
  .notifybadges {
    color: $primary-white;
    border-radius: 50%;
    padding: 1px 9px;
    position: absolute;
    top: 12px;
    right: 89px;
    font-size: small;
  }
}

@media only screen and (max-width:1298px) and (min-width:1100px) {
  .notifybadges {
    color: $primary-white;
    border-radius: 50%;
    padding: 1px 9px;
    position: absolute;
    top: 12px;
    right: 138px;
    font-size: small;
  }
}

@media only screen and (max-width:1379px) and (min-width:1299px) {
  .notifybadges {
    color: $primary-white;
    border-radius: 50%;
    padding: 1px 9px;
    position: absolute;
    top: 12px;
    right: 155px;
    font-size: small;
  }
}

@media only screen and (max-width:1499px) and (min-width:1379px) {
  .notifybadges {
    color: $primary-white;
    border-radius: 50%;
    padding: 1px 9px;
    position: absolute;
    top: 12px;
    right: 188px;
    font-size: small;
  }
}

@media only screen and (max-width: 600px) {
  .notifybadges {
    color: $primary-white;
    border-radius: 50%;
    padding: 1px 9px;
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: small;
  }
}

.messagetopbar {
  display: inline-block;
  width: 320px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.tab:focus-visible {
  outline: none;
}

.mainmenu-height-and-padding {
  border-radius: 38px 0px 0px 0px;
  height: 100vh;
}

.dashboardfont {
  font-weight: 500;
  color: $Pale-steel;
}