.BuildResume1-box-container-width {
    width: 140px;
}

.BuildResume1-box-container-widthAnd-height {

    width: 110%;
    height: 4px;
}

.BuildResume1-loader-padding {

    padding: 3px 10px;
}

.BuildResume1-checkbox-height-width {
    width: 18px;
    height: 18px;
}

.BuildResume1-fileupload-css {
    opacity: 0;
    position: absolute
}

.BuildResume1-www-image {
    transform: scale(0.8)
}

.BuildResume1-skype-image {

    transform: scale(0.7);

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 30px
}

.BuildResume1-skype-height {

    height: 39px
}