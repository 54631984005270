$primary-purple: #5E53BA;
$muted-purple: #9A9AB0;
$primary-white: #FFFFFF;
$primary-black: #000000;
$neutral-gray: #989898;
$dark-gray: #1E1E1E;
$sky-blue: #3F9AE0;
$bright-red: #F71010;
$bright-green: #00FF00;
$charcoal-gray: #515151;
$light-gray: #E1E1E1;
$lavender-gray: #7E73B5;
$off-white: #F7F7F7;
$medium-gray: #CCC;
$Purple-Indigo: #8743DF;
$Slate-gray: #8A8A8A;
$Graphite-gray: #2E2E2E;
$Lavender-mist: #F4EBFF;
$Deep-violet: #5B4FB7;
$Lavender-slate: #7E73B5;
$Pale-silver: #C2C2C2;
$Royal-blue: #464FEB;
$Deep-charcoal: #313131;
$Pale-taupe: #B2B0AF;
$Lavender-mist: #F6F4FF;
$Pale-gray: #F9F9F9;
$Soft-gray: #FBFBFB;
$Bright-orange: #FF8A00;
$Bright-red: #FF0000;
$Royal-purple: #5D12D2;
$Lavender-blue: #5E52B8;
$Ash-gray: #8F8F8F;
$Silver-gray: #BFBFBF;
$Flame-red: #FF0000;
$Mint-green: #2BC155;
$Fiery-red: #E11010;
$Sunset-orange: #FBA556;
$Slate-gray: #5C5C5C;
$Jet-gray: #212529;
$Steel-gray: #8D8D8D;
$Vibrant-orange: #F24E1E;
$Soft-silver: #EAEAEC;
$Mint-whisper: #DEF9C4;
$Forest-green: #508D4E;
$Deep-slate: #2E2E2E;
$Pale-sky-blue: #E0F2FF;
$Cerulean-blue: #3D6AD6;
$Electric-blue: #03A9F4;
$Deep-sky-blue: #0073B1;
$Crimson-red: #FF0000;
$Soft-lavender: #F6F4FF;
$Granite-gray: #818181;
$Silver-mist: #BABABA;
$Skyline-blue: #3F9AE0;
$Pale-steel: #BFBFBF;
$Ocean-blue: #0073B1;
$Whisper-gray: #F5F5F5;
$Cloudy-white: #E8EAEF;
$Pale-slate: #D6D8DC;
$Charcoal: #333333;
$Light-silver: #BBBBBB;
$Dim-Gray: #A9A9A9;
$Frosted-pearl: #F1F1F5;
$Lavender-haze: #E2E2EA;
$Stormy-sky: #696974;
$Alabaster-gray: #EAEAEA;
$Misty-gray: #92929D;
$Midnight-steel: #44444F;
$Pewter-gray: #ADADAD;
$Cloud-gray: #d7d7d7;
$Stone-gray: #BEBEBE;
$Lilac-frost: #ECDEFF;
$Deep-sapphire: #0056b3;
$Pale-cloud: #e9ecef;
$Obsidian: #292929;
$Sky-breeze: #E9F5FF;
$Pearl-gray: #d2d2d2;
$Platinum-gray: #B1B1B1;
$Crimson-red: #EC1010;
$Midnight-blue: #11142D