@import 'constants/colors.scss';

.rowborder {
  border: {
    color: $Frosted-pearl;
    width: 2px;
    style: solid;
    top-color: transparent;
    left-color: transparent;
  }

}

.messageboxborder {
  border: {
    color: $Frosted-pearl;
    width: 2px;
    style: solid;
    top-color: transparent;
    left-color: transparent;
  }
}

.primarybox {
  background: url(../icon/Rectangle.png) no-repeat 90% 55% $Soft-gray;
  -webkit-appearance: none;
  font-weight: 500;
  border: 1px solid $Lavender-haze;
  border-radius: 10px;
  outline: none;
}

.selectbtn {
  background: {
    color: $primary-white;
  }

  ;
  border-width: 1px;
  border-radius:7px;
}

@mixin headerfont($weight, $style, $size, $color) {
  font: {
    weight: $weight;
    style: $style;
    size: $size;
  }

  color:$color;
}

.name {
  @include headerfont(400, normal, 14px, $Stormy-sky);
  opacity: 0.7;
}

.time {
  @include headerfont(400, normal, 12px, $Stormy-sky);
}

.title {
  @include headerfont(500, normal, 14px, #000000);
}

.heading {
  @include headerfont(600, normal, 16px, #000000);
}

.message {
  font-weight: 600;
  font-size: 14px;
  color: $sky-blue;
}

.messagetextborder {
  width: fit-content;

  border: {
    color: $Frosted-pearl;
    width: 2px;
    style: solid;
    top-color: transparent;
    left-color: transparent;
    right-color: transparent;
  }
}

.sendmessage {
  font-weight: normal;
  font-size: 14px;
  color: $Misty-gray;
}

.messagedata {
  font-size: 14px;
  color: $Midnight-steel;
  font-weight: 500;
}

.messagefield {
  border: none;
  color: $primary-black;
  font-size: 12px;
}

.messagefield::placeholder {
  color: $Misty-gray;
  font-size: 14px;
}

// .res-circle {
//   /* (A) PERCENTAGE WIDTH & BORDER RADIUS */
//   width: 50%;
//   border-radius: 50%;

//   /* (B) BACKGROUND COLOR */
//   background: $sky-blue;

//   /* (C) NECESSARY TO POSITION TEXT BLOCK */
//   line-height: 0;
//   position: relative;
// }

// /* (D) MATCH HEIGHT */
// .res-circle::after {
//   content: "";
//   display: block;
//   padding-bottom: 100%;
// }

// /* (E) TEXT BLOCK */
// .circle-txt {
//   /* (E1) CENTER TEXT IN CIRCLE */
//   position: absolute;
//   bottom: 50%;
//   width: 100%;
//   text-align: center;
//   color: $primary-white;

//   /* (E2) THE FONT - NOT REALLY IMPORTANT */
//   font-family: arial, sans-serif;
//   font-weight: bold;
// }
.res-circle {
  width: 1.5rem;
  /* Set a fixed size for testing */
  height: 1.5rem;
  border-radius: 50%;
  background: skyblue;
  /* Use a hard-coded color for simplicity */
  position: relative;
  /* Needed for positioning children */
}

.circle-txt {
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust to perfectly center the text */
  text-align: center;
  color: white;
  /* Ensure contrast */
  font-family: Arial, sans-serif;
  font-weight: bold;
  z-index: 2;
  /* Ensure the text is on top */
}

@media(max-width:1000px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -222%;
    right: 160%;
  }
}

@media(width:820px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -310%;
    right: 160%;
  }
}

@media(max-width:768px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -224%;
    right: 163%;
  }
}

@media(max-width:767px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -504%;
    right: 32%;
    // top: -8rem;
    // right: 5rem;
  }
}

@media(max-width:540px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -515%;
    right: 28%;
  }
}

@media(width:430px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -411%;
    right: 30%;
  }
}

@media(width:414px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -411%;
    right: 30%;
  }
}

@media(width:412px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -411%;
    right: 30%;
  }
}

@media(width:390px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -411%;
    right: 30%;
  }
}

@media(width:375px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -411%;
    right: 30%;
  }
}

@media(width:360px) {
  .res-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: skyblue;
    position: relative;
    top: -411%;
    right: 30%;
  }
}