.signupforminputfield {
    background: $primary-white;
    border: 2px solid $neutral-gray;
    color: $primary-black;
    padding-left: 10px;
    font-size: 16px;
    font-family: Roboto;
    // width: 25.5rem;
    height: 2.5rem;
    border-radius: 0.3rem;

}

.signupforminputfield:focus {

    border: 2px solid $primary-purple;


}

.top-left-logo {
    position: absolute;
    top: 1rem;
    left: 2rem;
    width: 6rem;
    height: 2rem;
}

.rememberMe {
    font-family: Roboto;
    font-size: 0.8rem;
    font-weight: 400;
}

.image-style {
    // background: $primary-white
}

.signup-text {
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-weight: 600;
}

.signup-description {
    font-family: 'Roboto';
    font-size: 0.9rem;
    font-weight: 400;
    width: 11rem;
    height: 47px;
    color: $neutral-gray
}

.signupbtncss {
    height: 2.5rem;
    // width: 25.6rem;
    width: 214%;
    border-radius: 0.3rem;
}

.loginnbtcss {
    height: 2.5rem;
    // width: 25.6rem;
    width: 179%;
    border-radius: 0.3rem;
}

.orcss {
    font-family: Roboto;
    font-size: 0.8rem;
    font-weight: 800;
    color: $dark-gray;
}

.socialMediaicons {
    font-size: 1rem;
}

.haveAnAccount {
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 300;
    color: rgb(152, 152, 152);
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $primary-white;
    font-family: 'Roboto';
    // font-size: 2rem;
    font-weight: 600;
    width: 9rem;
    height: 4rem;
}

.eyeButton {
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
    color: $neutral-gray;
    font-size: 1rem;
    cursor: pointer;
    width: 24px;
    height: 16px;
}

.loginnow {
    text-decoration: none;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 500;
    color: $primary-white;
    cursor: pointer;
}

.whoami-text {
    font-weight: 400;
    font-size: 1rem;
    color: $primary-black;
    font-family: Roboto;
}

.typeofClient {
    font-family: Roboto;
    font-size: 0.7rem;
    font-weight: 400;

}

.heading-label {
    font-family: Roboto;
    font-size: 0.8rem;
    color: $neutral-gray;
    font-weight: 400;
}

.signupforminputtext {
    font-weight: 500;
    font-size: 0.7rem;
    color: $dark-gray;
}

.full-cover {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    border-radius: 0;
}

.FirstNameLastName {
    width: 108%;
    height: 2.5rem;
    border-radius: 0.3rem;
    border: 2px solid $neutral-gray;
}

.FirstNameLastName:focus {

    border: 2px solid $primary-purple;


}

.signupforminputfield::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $muted-purple;
}

.loginradio {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid $light-gray;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background-color: $primary-white;
}

.loginradio:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

.loginradio:checked:before {
    background: $sky-blue;

}

.signbtn {
    background: $sky-blue;
    border-radius: 8px;
    border: none;
    width: 100%;
    margin-top: 20px;
    height: 48px;
    font-family: Roboto;
    color: $primary-white;
    font-weight: bold;
    font-size: 16px;
}

.signbtn:hover {
    color: $primary-white;
}

#btwlabel {
    font-weight: normal;
    font-size: 12px;
    color: $muted-purple;
}

.radiotext {
    font-weight: 600;
    font-size: 16px;
    color: $sky-blue;
    margin-left: 10px;
    font-family: Roboto;
    cursor: pointer;
}

@media only screen and (min-width:300px) and (max-width:1199px) {
    .text {
        padding-left: 45%;
    }

    .text1 {
        padding-left: 50%;
    }
}

@media only screen and (min-width:1200px) {
    .text {
        padding-left: 39%;
    }

    .text1 {
        padding-left: 42%;
    }
}

@media only screen and (max-width:1199px) {
    .radiowrapper {
        width: 150%;
        margin-left: -105px;
        margin-bottom: 10px;
    }

    .radiowrapper1 {
        margin-bottom: 10px;
    }

    .namewrapper {
        margin-right: 30px;
    }
}

@media only screen and (min-width:1299px) {
    .signupclient {
        margin-right: 30px;
    }
}

@media only screen and (max-width:1599px) and (min-width:1499px) {

    .recruiter,
    .free {
        margin-top: -22px;
        margin-left: 25px;
        padding-top: 4px;
    }
}

@media only screen and (max-width:1499px) and (min-width:1400px) {
    .signupcontent {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px;
    }

    .signupcontentchild {
        margin-left: 5px;
        padding: 2px;
    }
}

@media only screen and (max-width:1400px) and (min-width:1299px) {
    .signupcontent {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px;
        margin-left: -3px;
        margin-right: 5px;
    }

    .signupcontentchild {
        margin-left: 10px;
        padding: 2px;
    }

    .recruiter {
        margin-top: -20px;
        margin-left: 27px;
    }
}

@media only screen and (max-width:1299px) and (min-width:1200px) {
    .signupcontent {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px;
        margin-left: -2px;
        margin-right: 14px;
    }

    .signupcontentchild {
        margin-left: 12px;
        padding: 2px;
    }

    .recruiter {
        margin-top: -20px;
        margin-left: 20px;

    }
}

.signcheck {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background: $primary-white;
    border: 2px solid $sky-blue;
    border-radius: 4px;
    cursor: pointer;
}

.signcheck:after {
    content: "";
    display: inline-block;
    position: relative;
    top: -3px;
    left: 9px;
    width: 3px;
    height: 7px;
    border-bottom: 2px solid $primary-white;
    border-right: 2px solid $primary-white;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

}

input[type=checkbox]:checked {
    // background: #327DFF;
    outline: none;
    border: 1px solid $lavender-gray;
    // border: 1px solid rgba(50, 125, 255, 1);


}

input:focus,
input:active {
    outline: none;
}

.recaptcha-wrap {
    position: relative;
    height: 76px;
    padding: 1px 0 0 1px;
    background: $off-white;

    >div {
        position: absolute;
        bottom: 2px;
        right: 2px;
        font-size: 10px;
        color: $medium-gray;
    }
}

// Hides top border
.recaptcha-wrap:after {
    content: '';
    display: block;
    background-color: $off-white;
    height: 2px;
    width: 100%;
    top: -1px;
    left: 0px;
    position: absolute;
}

// Hides left border
.recaptcha-wrap:before {
    content: '';
    display: block;
    background-color: $off-white;
    height: 100%;
    width: 2px;
    top: 0;
    left: -1px;
    position: absolute;
    z-index: 1;
}

// Makes it responsive & hides cut-off elements
#g-recaptcha {
    overflow: hidden;
    height: 76px;
    border-bottom: 1px solid $off-white;
    position: relative;
    box-sizing: border-box;
    max-width: 294px;
}

//Registration completed
.saveregister {
    background: $primary-purple;
    border-radius: 4px;
}

.saveregister label {
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    color: $primary-white;
    margin-bottom: 0px;
}

.signUpImageMarginLeft {
    margin-left: -1%;
}

.signupContainerMarginTop {
    margin-top: 5%;
}

.signUpbtnloadingIcon {
    display: none;
    padding: 5px 15px
}