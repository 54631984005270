.Resume-snapShort-checkbox-height-And-width {
    width: 18px;
    height: 18px;
}
.Resume-snapShort-file-upload-position-css {
    opacity: 0;
     position: absolute
}
.Resume-snapShort-loader-padding-css {
  padding: 3px 10px;
}
.Resume-snapShort-skype-css {
transform: scale(0.7);
background-color: #F9F9F9;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
padding-right: 30px;
}
.Resume-snapShort-skype-height-css {
    height: 39px;
    }
    .Resume-snapShort-www-transform-css {
       transform: scale(0.8);
        }