.candidate-perrmanentData-container {
    max-width: 210px;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.candidate-perrmanentData-email-wraper-div {
    word-wrap: break-word;
    white-space: pre-wrap;
    margin: 10px;
}

.candidate-perrmanentData-calender-icon {
    transform: scale(0.8);
}