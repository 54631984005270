.uploadRequirement-container-width {
    height: "100vh"
}

.uploadRequirement-btn-css {
    position: absolute;
    opacity: 0;
}

.uploadRequirement-loader-padding {
    padding: 3px 10px;
}

.uploadRequirement-buildajobRequirement {
    margin-left: '0.7rem';
}