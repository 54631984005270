@import 'constants/colors.scss';

@mixin fontstylenormal {
    font: {
        style: normal;
    }
}


.globalfontrule {
    @include fontstylenormal;

    font: {
        weight: 600;
    }
}

.statusdropdown {
    background: url(../icon/Rectangle.png) no-repeat 90% 55% $primary-white;
    -webkit-appearance: none;
    font-weight: 500;
    border: none;
    outline: none;
}

.parafont {
    @include fontstylenormal;

    font: {
        font-family: "Roboto";
        font-size: 0.8rem;
        fontWeight: 300;
        color: '$dark-gray'
    }

    // color:#313131;
}

.circular-progress {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: conic-gradient($Deep-violet 0deg 270deg, #d3d3d3 270deg 360deg);
    transform: rotate(221deg);
}

.circular-progress::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 39px;
    /* Adjust the inner circle size as needed */
    height: 39px;
    /* Adjust the inner circle size as needed */
    border-radius: 50%;
    background: $primary-white;
}

.circular-progress .progress-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(139deg);
    font-size: 16px;
    /* Adjust the font size as needed */
    color: $Deep-violet;
    font-weight: bold;
    // transform: rotate(221deg);

}


.starbtn {
    background: {
        image: url("../icon/star.png");
        repeat: no-repeat;
        position: 15% 50%;
        color: rgba(255, 138, 0, 0.2);
    }

    border: {
        radius: 8px;
    }

    width:61px;
    height:32px;
}

@mixin btn {
    font: {
        weight: 500;
        size: 14px;
    }

    border-radius: 10px;
    font-style:normal;
}

.sharejobbtn {
    @include btn;
    color: $Platinum-gray;
    border: 1px solid $Platinum-gray;
}

.shortlistjob {
    @include btn;
    color: $primary-black;
    border: 1px solid $sky-blue;

}

.main {
    display: block;
    position: relative;
    padding-left: 25px;
    font-weight: 500;
    color: $primary-black;
    cursor: pointer;
    font-size: 14px;
}

/* Hide the default checkbox */
.main input[type=checkbox] {
    visibility: hidden;
}

/* Creating a custom checkbox*/
.geekmark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 16px;
    width: 16px;
    // background-color: $Pale-steel;
    border: 2px solid $Deep-violet;
    border-radius: 3px;
}

/* Specify the background color to be
shown when checkbox is checked */
.main input:checked~.geekmark {
    background-color: $Deep-violet;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Display checkmark when checked */
.main input:checked~.geekmark:after {
    display: block;
}

.main .geekmark:after {
    top: 2px;
    left: 6px;
    width: 3px;
    height: 7px;
    border-bottom: 2px solid $primary-white;
    border-right: 2px solid $primary-white;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

// .rejectbtn:hover {
//     background-color: $Crimson-red;
//     color: $primary-white;
// }
.rejectbtn {
    background-color: $Crimson-red;
    color: $primary-white;
}

.rejectbtn:hoverPopUpContainerSecond-content {
    background-color: $Crimson-red;
    color: $primary-white;
}

.interviewtext,
.rejecttext {

    color: $primary-black;
}

.interviewtext:hover {
    color: $primary-white;
}

.rejecttext:hover {
    color: $primary-white;
}

.dropdown-toggle::after {
    content: none;
}

.dropdown-menu {
    width: 100%;
}