@import 'constants/colors.scss';

.addbtn {
    background-color: $sky-blue;
    border-radius: 10px;

    padding: {
        top: 10px;
        bottom: 10px;
    }

    width: 120px;
    color: $primary-white;
    border:none;
}