@import "../constants/colors.scss";

.jobapplyCard-border-radius {
    border-radius: 24px
}

.applied-btn-margin-css {
    // margin-top: -69rem;
    // margin-left: 77%;
}

.apply-job-btncss {
    // margin-top: -1rem;
    background-color: $Deep-violet;
    // margin-left: 15rem;
}

.apply-job-btnloadercss {
    padding: 3px 5px
}