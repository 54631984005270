@import '../../constants/colors.scss';

.applicationData-conatiner-padding-bottom {
    padding-bottom: 11%;
}

.applicationData-wordWrapand-whitespace {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.applicationData-btn-css {
    font-style: normal;

    width: 100px;

    padding: 3px 10px;
}

.applicationData-btn-css:hover {
    color: $primary-white
}

.client-applicationData-margin-left-2rem {
    margin-left: -2rem
}

@media (min-width:1000px) {
    .col-1 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-3 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-4 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-8 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .CircularProgressbar {
        width: 68% !important;
        vertical-align: middle;
    }

    .margin-left-74 {
        margin-left: -22%;
    }

    .margin-left--2 {
        margin-left: -2%;
    }

    .col-21 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .font-size-14px {
        font-size: 13px;
    }

    .col-81 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .col-111 {
        padding-left: 15px;
        padding-right: 15px;
    }
}