@import '../constants/colors.scss';

.JobRequirment3-container-padding-bottom {
    padding-bottom: "8%";
}

.jobRequirment3-header-design-container-style {
    width: 140px;
}

.jobRequirment3-Requirement-info-border {
    width: 100%;
    height: 4px;
}

.jobRequirment3-loader-padding {
    padding: 3px 10px;
}