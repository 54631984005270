@import 'constants/colors.scss';

.contentholder {
  border-radius: 20px;
  font-family: Roboto;
  background-color: $primary-white;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
}



@mixin fontweight {
  font: {
    weight: bold;
  }
}

@mixin card($wght, $sx, $cl) {
  font: {
    weight: $wght;
    size: $sx;
  }

  color: $cl;
}

@mixin filldetailsfontmix($fsx, $fwght, $fclr) {
  font: {
    size: $fsx;
    weight: $fwght;
  }

  color: $fclr;
}

.filldetailsfont0 {
  @include filldetailsfontmix(24px, 600, $Slate-gray);
}

.filldetailsfont1 {
  @include filldetailsfontmix(24px, 600, $primary-black);

  @media screen and (max-width: 580px) {
    font-size: 20px;
  }

  @media screen and (max-width: 530px) {
    font-size: 18px;
  }

  @media screen and (max-width: 508px) {
    font-size: 16px;
  }

  @media screen and (max-width: 486px) {
    font-size: 14px;
  }

  @media screen and (max-width: 462px) {
    font-size: 13px;
  }
}

.sidebarfont {
  font-weight: 500;
  font-size: 14px;
  // color: $Obsidian;
}

.dashboardforminputtext {
  @include card(400, 0.8rem, $primary-black);
  font-family: 'Roboto';
}

// .smallcard{
//     @include card(500,14px,$sky-blue);
// }
.smallcard:hover {
  color: $sky-blue;
}

.smallcardborder {
  background: {
    color: $Whisper-gray;
  }

  border: {
    radius: 20px;
  }
}

@mixin generatecard($fs) {
  font-style: normal;
  font-weight: 600;
  font-size: $fs;
  color: $primary-black;
}

.ratecardtitle {
  @include generatecard(30px);
}

table.table-bordered {
  border: 1px solid $primary-black;
}

table.table-bordered>thead>tr>th {
  border: 1px solid $primary-black;
}

table.table-bordered>tbody>tr>td {
  border: 1px solid $primary-black;
}

.table thead {
  color: $primary-black;
  font-size: 14px;
}

.table tbody {
  font-size: 12px;
  font-weight: 500;
}

.generatewhitefield {
  width: auto;
  border: none;
  text-align: center;
}

.generatewhitefield:disabled {
  width: auto;
  border: none;
  background-color: $primary-white;
}

// .cardcheck {
//   -webkit-appearance: none;
//   width: 20px;
//   height: 20px;
//   background: $primary-white;
//   border: 2px solid $Stone-gray;
//   border-radius: 4px;
//   cursor: pointer;
// }
.cardcheck {
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid $Deep-violet;
  border-radius: 3px;
  background-color: $primary-white;
  display: inline-block;
  position: relative;
}

.cardcheck:checked {
  background-color: $Deep-violet !important;

}

.cardcheck:checked::before {
  content: "\2713";
  /* Unicode character for check mark */
  color: $primary-white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .cardcheck:after {
//   content: "";
//   display: inline-block;
//   position: relative;
//   top: -1px;
//   left: 8px;
//   width: 4px;
//   height: 7px;
//   border-bottom: 2px solid #fff;
//   border-right: 2px solid #fff;
//   transform: rotate(45deg);
//   -webkit-transform: rotate(45deg);
// }

@mixin btn($cl, $rds, $wdth) {
  background: {
    color: $cl;
  }

  border: {
    radius: $rds;
  }

  font: {
    weight: $wdth;
  }
}

// .firstbtn{
//     border:1px solid $sky-blue;
//     color:$primary-white;
//     @include btn($sky-blue,10px,500);
// }
// .secondbtn{
//     @include btn($Mint-green,10px,500);
//     color:$primary-white;
// }
.cancelbtn {
  color: $sky-blue;
  font-size: 14px;
}

.cancelbtn:hover {
  text-decoration: none;
  color: $sky-blue;
}

.firstbtn:hover {
  color: $primary-white;
}

.secondbtn:hover {
  color: $primary-white;
}

.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.radiosize {
  width: 20px;
  height: 20px;
}

@mixin content($c, $s) {
  font-family: Poppins;
  font-weight: 500;
  font-size: $s;
  color: $c;
}

.dashboardforminputfield::placeholder {
  @include content($Light-silver, 14px);
}

.input-placeholder input:valid+.placeholder {
  display: none;
}

.placeholder {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  height: 20px;
  left: 30px;
  padding: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $Light-silver;
}

.placeholder span {
  color: $bright-red;
}

.signup_placeholder {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  height: 20px;
  left: 30px;
  padding: 15px;
  padding-left: 10px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a9ab0;
}

.signup_placeholder span {
  color: $bright-red;
}

.input-signup-placeholder input:valid+.signup_placeholder {
  display: none;
}

.signup_name_placeholder {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  height: 20px;
  left: 30px;
  padding: 31px;
  padding-left: 10px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a9ab0;
}

.signup_name_placeholder span {
  color: $bright-red;
}

.input-signupName-placeholder input:valid+.signup_name_placeholder {
  display: none;
}

@mixin upcancelbtn($bgclr, $clr, $fwght, $fsz) {
  background: {
    color: $bgclr;
  }

  color: $clr;

  font: {
    weight: $fwght;
    size: $fsz;
  }
}

.uploadresumebtn {
  border-radius: 10px;
  padding: 0px 8px;
  @include upcancelbtn($Mint-green, $primary-white, 500, 16px);
}

.uploadbtn {

  border-radius: '0.5rem';
  // margin-top: 10% !important;
  // margin-left: -68% !important;


  // @include upcancelbtn(rgb(126, 115, 181), $primary-white, 500, 14px);

  @include upcancelbtn(#06D001, $primary-white, 500, 14px);

}

.browsebtn {
  border-radius: 5px;
  // @include upcancelbtn($sky-blue, $primary-white, 500, 14px);
  @include upcancelbtn($lavender-gray, $primary-white, 500, 14px);
  text-align: center;
}

.browsebtn:hover,
.uploadbtn:hover {
  color: $primary-white;
}

.uploadresumetext {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $primary-black;
}

.resumepreviewname {
  font: {
    style: normal;
    weight: normal;
    size: 12px;
  }
}

.resumepreviewbtn {
  background: {
    color: $Mint-green;
  }

  border: {
    radius: 15px;
  }

  padding: 12px 12px;
}

.resumepreviewwhitebtn {
  border: 1px solid $Pearl-gray;

  box: {
    sizing: border-box;
  }

  border: {
    radius: 24px;
  }

  background: {
    color: $primary-white;
  }

  font: {
    weight: bold;
  }
}

.dashboardheadingfont {
  color: $primary-black;
  font-weight: 500;
  font-size: 16px;
}

.resumeuploadtextbox {
  height: 45px;
  background: $Soft-gray;
  border: 1px solid #c2c2c2;
  border-radius: 10px;

  font: {
    weight: 600;
    size: 14px;
  }

  padding-left: 0.8rem;
  color: $Pale-steel;
}

.resumeuploadtextbox::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $Pale-steel;
}

.resumeuploadtextbox:disabled {
  height: 45px;
  background: $Soft-gray;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  padding-left: 0.8rem;
  padding-bottom: 7px;
  color: $Pale-steel;
}

.resumeuploadtextbox:disabled::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $Pale-steel;
}

.dashboardforminputfield {
  color: $primary-black;
  height: 45px;
  background: $Cloudy-white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 0.8rem;
  font-family: 'Roboto';
}

// .dashboardforminputfield:focus {
//   color: $primary-black;
//   height: 45px;
//   /* background: $Soft-gray; */
//   border: 1px solid $lavender-gray;
//   border-radius: 10px;
//   font-size: 12px;
//   padding-left: 30px;
//   // border: none;
//   background: $Cloudy-white;
// }
.dashboardforminputfield:focus {
  outline: none;

  border: 2px solid $Deep-violet;

  box-shadow: 0 0 5px rgba(91, 79, 183, 0.5);
  // background: $Pale-slate;
  font-family: Roboto;
  // color: $Charcoal;
  background: $Pale-slate;
  color: $Charcoal;
}

.dashboardforminputfield:disabled {
  color: #888888;
  height: 45px;
  background: $Whisper-gray; // border: 1px solid #c2c2c2;
  border-radius: 10px;
  font-size: 14px;
  padding-left: 0.8rem;
  cursor: not-allowed;
  font-family: Roboto;
  color: #888888;
}

.dashboardforminputfield:disabled::placeholder {
  @include content($Light-silver, 14px);
}

.resumepreviewname {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.resumepreviewbtn {
  background-color: $Mint-green;
  border-radius: 15px;
  padding: 12px 12px;
}

.resumepreviewwhitebtn {
  border: 1px solid $Pearl-gray;
  box-sizing: border-box;
  border-radius: 22px;
  background-color: $primary-white;
  font-weight: bold;
}

/*dashboard view*/
.dashboardplace {
  position: relative;
  transform: scale(0.9);
}

.center {
  position: absolute;
  top: 25%;
  left: 20px;
  font-weight: 500;
  font-size: 14px;
  color: $primary-black;
}

.build {
  background: #c2c2c2;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $primary-black;
  margin-bottom: 0px;
  font-family: Poppins;
}

@media only screen and (max-width: 1199px) and (min-width: 499px) {
  .viewbox {
    margin-left: 10px;
  }
}

// @media only screen and (min-width:1199px) and (max-width:1401px){
// .viewresponsive{
//     width:1000px;
// }
// }
@media only screen and (min-width: 499px) and (max-width: 599px) {
  .uploadbox {
    width: 450px;
    margin-left: -25%;
  }
}

@media only screen and (min-width: 599px) and (max-width: 999px) {
  .uploadbox {
    width: 450px;
    margin-left: -10%;
  }
}

.dashboardfontusername {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
}

/*PROFILE*/
.backicon {
  font-size: 22px;
  color: $primary-black;
  cursor: pointer;
}

.backicon:hover {
  color: $sky-blue;
}

button:focus {
  outline: none;
}

input[type="checkbox"]:focus {
  outline: none;
}

//setting
.settingforminputfield {
  border-bottom: 1px solid $primary-black;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-bottom: 30px;
}

@mixin forminputtext($fs) {
  font-style: normal;
  font-weight: 600;
  font-size: $fs;
  line-height: 24px;
  color: $Slate-gray;
}

.settingforminputtext {
  @include forminputtext(16px);
}

.settingheading {
  @include forminputtext(24px);
}

.settingforminputfield::placeholder {
  font-weight: 600;
  font-size: 14px;
  color: $Pale-steel;
  width: 182px;
  padding-bottom: 42px;
}

.settinguploadbtn {
  border-radius: 10px;
  @include upcancelbtn($Mint-green, $primary-white, 500, 14px);
}

.settinguploadbtn:hover {
  color: $primary-white;
}

.custom-control-label {
  cursor: pointer;
}

.navbartopbarselectrole {
  border: none;
  background-color: transparent;
  outline: none;
}

.dashboardviewmorelink {
  border: 1px solid $lavender-gray;
  border-radius: 14px;
  width: 100px;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto;
  color: $lavender-gray;
}

.dashboardviewmorelink:hover {
  color: $primary-white;
  background-color: $lavender-gray;
}

#zipCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

#empzipCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

#secondzipCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

#preLocCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

#curLocCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

#secLocCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

#firstLocCodeDiv {
  background-color: $primary-white;
  color: $primary-black;
  font-weight: 500;
  font-size: 14px;
  padding-left: 5%;
  border: 1px solid $Light-silver;
  overflow-y: auto;
  height: 150px;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 90%;
}

.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
  color: rgb(234, 234, 234) !important;
  stroke-width: 2px;
  stroke: #d7d7d7 !important;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}