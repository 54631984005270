.client-mathingjobcard-card-shadow {

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.client-matchingjob-description-css {
    font-Size: 14px;
    display: -webkit-box;
    Webkit-line-clamp: 2;
    Webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    line-height: 1.2em;
    max-height: 2.4em;
    color: '#7E73B5'
}