@import 'constants/colors.scss';

@mixin btn($brpt, $bclr, $fw, $fs, $pl, $pr, $clr) {
    background: {
        repeat: $brpt;
        color: $bclr;
    }

    font: {
        weight: $fw;
        size: $fs;
    }

    padding: {
        left: $pl;
        right: $pr;
    }

    color:$clr;

}

// .grpbtn{
//     @include btn(no-repeat,$primary-white,600,14px,50px,90px,#5C5C5C);
//     background: {
//         image:url("../icon/downarrow1.png");
//         position-x:8%,90%;
//         position-y:40%,50%;
//     }
// }

.placetext::placeholder {
    color: $Pewter-gray;
    font-size: 14px;
}

.filterbtn {
    /* background-repeat: no-repeat; */
    background-color: $Lavender-mist;
    font-weight: 500;
    font-size: 14px;
    margin-left: 68%;
    // padding-right: 25px;
    /* color: #F94687; */
    /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC3SURBVHgBxZQxDoJAEEVn1MTWI+hluIZnsNQKG229gtfgMu4NtDUBlhlCsWRD/kwBvIZlAsOf8HaZnNTF49wQv2R5GEphS7HcVbe33mzIiTQrk2bKcfhAD/+L50eL5GRfXVmv8n5MysGdEMHO5zXRl8YjK0ESn3ThTthGumiDpPST2p3mIhsZaYHIRkZawIRT2kxogVhAG6QFIku4uBYI806x6mT+KVad3KcN0GnF08aqkzmhVacOdCFb86oq2J4AAAAASUVORK5CYII=); */
    /* background-position-x: 20%; */
    /* background-position-y: 50%; */
    /* background-color: #FFEBF2; */
    // border-radius: 10px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    border: none;
}

// .popup-content {
//     margin: auto;
//     background: ' #EAEAEA' !important;
//     width: 50%;
//     padding: 5px;
//     border: 1px solid $Cloud-gray;
//     margin-top: -1.6rem !important;
//     margin-left: 0.5rem !important;
//     border: none !important;
//     border-radius: 0.3rem !important;
//     padding: 0.2rem !important;
//     border: 1px solid #d7d7d7d2;
// }
.PopUpContainerSecond-content {
    margin: auto;
    width: 171px !important;
    height: 156px !important;
    background: $Alabaster-gray !important;
    border: 1px solid $Cloud-gray;
    margin-top: -1.6rem !important;
    margin-left: 0.2rem !important;
    // border: none !important;
    border-radius: 0.3rem !important;
    padding: 0.2rem !important;
    border: 1px solid $Cloud-gray !important;
    // top: 158.955px !important;
    // left: 684.955px !important;
    padding: 7px !important;
}

.PopUpContainerSecondF-content {
    margin: auto;
    width: 171px !important;
    height: 156px !important;
    background: $Alabaster-gray !important;
    border: 1px solid $Cloud-gray;
    margin-top: -0.2rem !important;
    margin-left: 0.2rem !important;
    border-radius: 0.3rem !important;
    padding: 0.2rem !important;
    border: 1px solid $Cloud-gray !important;
    padding: 7px !important;
}

// element.style {
//     position: absolute;
//     z-index: 999;

//     background: rgb(234, 234, 234);
//     border-radius: 10px;
//     pointer-events: auto;

// }
.PopUpContainerThird-content {
    position: absolute;
    z-index: 999;
    width: 173px !important;
    height: 90px !important;
    background: $Alabaster-gray !important;
    border-radius: 0.3rem !important;
    border: 1px solid $Cloud-gray !important;
    margin-top: -0.4rem !important;
    margin-left: 0.2rem !important;
    pointer-events: auto;

    padding: 7px !important;
}

.PopUpContainerThird-arrow {

    display: none;
}

.PopUpContainerSecond-arrow {

    display: none;
}

.PopUpContainerSecondF-arrow {

    display: none;
}

.findbtn {
    @include btn(no-repeat, $sky-blue, 500, 14px, 70px, 25px, $primary-white);

    background: {
        image: url("../icon/search.png");
        position-y: 50%;
        position-x: 25%;
    }

    padding: {
        top: 10px;
        bottom: 10px;
    }

    border-radius:10px;
    border:none;
}


.checkbx {
    background: $Stone-gray;
    width: 20px;
    height: 20px;
    position: relative;
    top: 10%;
    cursor: pointer;
}

.headingfont {
    font-size: 0.9rem;
    font-family: Roboto;
    // font-size: 1rem;
    font-weight: 600;
    /* line-height: 25.78px; */
    text-align: left;
    color: $lavender-gray;
}

.navbtn {
    width: 62px;
    height: 53px;

    background: {
        color: transparent;
    }

    border: {
        radius: 20px;
    }
}

.navbtnselected {
    color: $primary-white;
    width: 62px;
    height: 53px;

    background: {
        color: $sky-blue;
    }

    border: {
        radius: 20px 20px 20px 20px;
    }
}

.pagstyle {
    background: {
        color: $Lilac-frost;
    }
}

@mixin filter($st, $wdth, $sx, $clr) {
    font: {
        style: $st;
        weight: $wdth;
        size: $sx;
    }

    color:$clr;
}

.filterfont {
    @include filter(normal, normal, 14px, $Pewter-gray);
}

.filterfontright {
    @include filter(normal, 600, 18px, $Dim-Gray);

    font: {
        style: normal;
        weight: 600;
        size: 14px;
    }

    color:$Dim-Gray;
}

.filterbttn {
    // background: {
    //     color: #E9F5FF;
    // }

    border: {
        radius: 10px;
    }

    border:1px solid $sky-blue;
    color:$sky-blue;
}

hr.divider {
    margin-top: 0px;
    margin-bottom: 5px;
}

@mixin modalandclearbtn($fst, $fwdth, $fsx, $clr) {
    font: {
        style: $fst;
        weight: $fwdth;
        size: $fsx;
    }

    color:$clr;
}

.modaltext {
    @include modalandclearbtn(normal, 500, 0.9rem, $dark-gray);
}

.clearbtn {
    @include modalandclearbtn(normal, 500, 21px, $Deep-violet);

    background: {
        color: transparent;
    }

    border: {
        style: none;
        color: transparent;
        width: 0;
    }
}

.clearbtn:hover {
    color: $bright-red;
}

.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    margin-top: 150px;
    margin-left: 15px;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal {
    pointer-events: none;
}

.modal.right .modal-content {
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 260px;
}

.modal-content {
    border: none;
}

body.modal-open {
    overflow-y: scroll !important;
    padding-right: 0 !important;
}

.scrollmodal {
    position: sticky;
    top: 0;
    height: 60px !important;
    z-index: 10;
    background-color: $Soft-lavender;
    ;
}

.textColor {
    color: $Deep-violet;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-top: 10px;


}

.labelDesign {
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 500;
    color: $Deep-violet;
    margin-top: 10px;
    margin-bottom: 10px;

}

.modalradio {
    -webkit-appearance: none;
    width: 16.09px;
    height: 16.09px;
    border: 2px solid $Deep-violet;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}

.modalradio:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

.modalradio:checked:before {
    background: $lavender-gray;
}

.matchingjobdata {
    font-weight: 500;
    color: $lavender-gray;
    font-family: Roboto;
    font-size: 0.8rem;
    font-weight: 400;
}

.matchingjobdatalink {
    // font-weight: 500;
    // color: $primary-black;
    font-family: Roboto;
    color: $Deep-sapphire;
    text-decoration: underline;
    font-size: 0.8rem;
    font-weight: 600;
}

.matchingjobdatalink:hover {
    color: $Deep-sapphire;
    text-decoration: underline;
}

.matchingjobdata:hover {
    text-decoration: none;
}

.pagination #p1 {
    width: 150px;
    height: 50px;
    background: $primary-white;
    border-radius: 20px;
    cursor: pointer;
}

#p1 label,
#p2 label {
    font-family: roboto;
    font-style: normal;
    font-weight: 1000;
    font-size: 16px;
    line-height: 27px;
    color: $sky-blue;
    text-decoration: none;
    margin-left: 30px;
    margin-top: 10px;
}

.page {
    width: 215px;
    height: 50px;
    background: rgba(63, 154, 224, 0.3);
    ;
    border-radius: 20px;
}

.page a {
    text-decoration: none;
    display: inline;
    float: left;
    padding: 0px 22px;
    font-family: roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    color: $sky-blue;
}

a.active {
    background-color: $sky-blue;
    color: $primary-white;
    border-radius: 20px;
}

.page a:hover:not(.active) {
    background-color: $primary-white;
    border-radius: 20px;
}

.pagination #p2 {
    width: 110px;
    height: 50px;
    background: $primary-white;
    border-radius: 20px;
}

@media only screen and (max-width:599px) {
    .paginationresponsive {
        margin-left: -75px;
        width: 480px;
    }
}

.searchbxfocusbdr:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

//clint job posting preview
.previewfield {
    border: none;
    border-bottom: 2px solid $primary-black;
    width: 10%;
}

.checkboxcustomcss {
    width: 18px;
    height: 18px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid $Deep-violet;
    border-radius: 3px;
    background-color: $primary-white;
    display: inline-block;
    position: relative;
}

.checkboxcustomcss:checked {
    background-color: $Deep-violet !important;

}

.checkboxcustomcss:checked::before {
    content: "\2713";
    /* Unicode character for check mark */
    color: $primary-white;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modalcheckbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid $Deep-violet;
    border-radius: 3px;
    background-color: $primary-white;
    display: inline-block;
    position: relative;
}

.modalcheckbox:checked {
    background-color: $Deep-violet !important;
}

.modalcheckbox:checked::before {
    content: "\2713";
    /* Unicode character for check mark */
    color: $primary-white;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// .PopupOne {
.popuptext {
    font-family: 'Roboto';
    color: $dark-gray;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    padding-left: 0.5rem;
    padding-top: 0.3rem;
}

// }

.thick-circle {
    .CircularProgressbar-path {
        stroke-width: 11px !important;

    }


}

.thick-circle {
    .CircularProgressbar-trail {
        stroke-width: 11px !important;
    }
}

// .thick-circle {
//     .CircularProgressbar {
//         width: 100% !important;
//         height: 100% !important;
//         box-sizing: border-box;
//         padding: 10px;
//         /* Adjust padding if needed */

//     }
// }

.popuptext:focus {
    // color: $primary-$primary-black;
    // font-weight: 500;
    // font-size: 14px;
    cursor: pointer;

    border: none;
    outline: none;
}

// new changes
// .dropdownfilter h4 {
//     font-size: 18px;
//     font-weight: 500;
// }

.skill {
    font-size: 15px;
    font-weight: 500;

}

// .modal-content.popupsecondbox.newmodelbox {
// width: 600px!important;
// height: 60vh!important;
// }
.btn {
    font-size: 14px;
}

button.btnshow {
    color: $sky-blue;
    border: none;
    background: none;
}

// .modal-content.popupsecondbox.newmodelbox {
//  position: absolute !important;
// }
.modal .popupsecondbox .modal-dialog .modal-content {
    width: 600px !important;
    height: 60vh;
    //overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    border: 2px solid $Soft-silver;
}

.filterdirectory {
    padding: 10px 18px 10px 0 !important;
    margin-right: 18px !important;
    margin-left: 10px !important;
    height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    $primary-white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    list-style: none;
}

.filterDirectorylisttitle {
    font-weight: 500;
    text-transform: capitalize;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #282c3f;
}



.matchingJobCard {
    background-color: $primary-white;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}


.page-link {
    color: $primary-black;
    font-weight: normal;
    border: none;
    background-color: $Soft-lavender;
}

.page-item.active .page-link {
    z-index: 3;
    color: $primary-black;
    background-color: $Soft-lavender;
    border-color: none;
    font-weight: 600;
}

.page-item.disabled .page-link {
    background-color: $Soft-lavender;
}

.page-link:hover {
    z-index: 2;
    color: $lavender-gray !important;
    text-decoration: none;
    background-color: $Pale-cloud;
    border-color: none;
}