@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import 'constants/colors';

.forminputfield {
    background: $primary-white;
    border: 2px solid $neutral-gray ;
    border-radius: 8px;
    height: 58px;
    color: $primary-black;
    padding-left: 10px;
    font-size: 15px;
    font-family: Roboto;
    // width: 25.5rem;
    height: 2.5rem;
    border-radius: 0.3rem;
    border: 2px solid $neutral-gray;
}

.forminputfield:focus {
    border: 2px solid $primary-purple;
}

.full-cover-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    // margin-top: -20rem;
    border-radius: 0 0.5rem 0.5rem 0;
    /* Ensures the image covers the container without stretching */
}

.userTypeLogin {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: $neutral-gray
}

.login-description {
    font-family: 'Roboto';
    font-size: 0.9rem;
    font-weight: 400;
    width: 12.2rem;
    height: 47px;
    color: $neutral-gray;
}

.login-description-color {
    color: $neutral-gray;
}

.error-msg-font-weight {
    font-weight: "600"
}

.Img-container-Margin-top {
    margin-left: -1%;
}

.form-container-Margin-top {
    margin-top: '5%'
}

.forgot-password {
    font-family: Roboto;
    color: $muted-purple;
    text-decoration: none;
    font-size: 0.9rem;
}

.forgot-password-link {
    font-family: "Roboto";
    color: $muted-purple;
    text-decoration: "none";
    font-size: '0.8rem';
    font-weight: '400';
    margin-right: '1.2rem';
}

.error-msg-font-weight-500 {
    font-weight: 500;
}

.login-btn-padding-loader {
    display: "none;";
    padding: "5px 15px"
}

.forminputfield::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $muted-purple;
}

.dhaaSign-up-now {
    text-Decoration: "none";
    color: $dark-gray;

}

.btnsign {
    background: $primary-purple;
    // background: linear-gradient(90deg, $primary-purple 0%, #8D7AC2 100%);

    border: none;
    // width: 24rem;
    margin-top: 0px;
    height: 3rem;
    text-align: center;
    font-family: Roboto;
    color: $primary-white;
    font-weight: bold;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0px;
    box-shadow: none;
    border-radius: 0.3rem;
}

.eyeButton-login {
    position: absolute;
    right: 1.9rem;
    top: 0.7rem;
    color: $neutral-gray;
    font-size: 1rem;
    cursor: pointer;
}

.top-left-logo-login {
    position: absolute;
    top: 1rem;
    left: 2rem;
    width: 9rem;
    height: 2rem;
}

.remembermecheckbox {
    width: 13px;
    height: 13px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #989898;
    border-radius: 3px;
    background-color: $primary-white;
    display: inline-block;
    position: relative;
}

.remembermecheckbox:checked {
    background-color: $Deep-violet !important;
}

.remembermecheckbox:checked::before {
    content: "\2713";
    /* Unicode character for check mark */
    color: $primary-white;
    font-size: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rememberme-redio {
    margin-top: 0.4rem;
}

.donothaveAccount-margin {
    margin-left: 33% !important;
}

.donothaveAccount-margin-signup {
    margin-left: 25% !important;
}

// .btnsign:hover {
//     color: $primary-white
// }
.btnsign:disabled {
    opacity: .4;
}

.btnsign {
    border: none;
    color: $primary-white;
    padding: 9px 24px;
    font-size: 16px;
}

#btwlabel1 {
    font-weight: normal;
    font-size: 12px;
    // color: $muted-purple;
    padding-top: 5px;
    font-weight: 600;
}

.btnlinkedln {
    background-color: $primary-white;
    border: 2px solid $sky-blue;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: $sky-blue;
    font-family: Roboto;
    margin-bottom: 0px;
    margin-top: 0px;
}

.btnlinkedln:hover {
    color: $sky-blue;
}

@media only screen and (min-width:1199px) {
    .loginclient {
        margin-right: 18px;
    }

    .logincontent {
        margin-top: 40px;
        margin-left: 10px;
    }

    .loginI {
        padding: 0px;
    }
}

@media only screen and (max-width:1499px) and (min-width:1299px) {
    .loginI {
        margin-right: 2%;
    }

}

@media only screen and (max-width:1298px) and (min-width:1100px) {
    .loginI {
        margin-right: 4%;
    }

}

@media only screen and (max-width:1499px) and (min-width:1200px) {
    .logincontent {
        white-space: nowrap;
        overflow: hidden;
        padding: 3px;
    }
}

@media only screen and (max-width:1499px) and (min-width:1379px) {
    .freelance {
        white-space: nowrap;
        overflow: hidden;
        padding: 3px;
        margin-left: 6px;
    }
}

@media only screen and (max-width:1379px) and (min-width:1299px) {
    .freelance {
        white-space: nowrap;
        overflow: hidden;
        padding: 3px;
        margin-left: 5px;

    }

    .free {
        margin-top: -20px;
        padding-left: 10px;
    }

}

@media only screen and (max-width:1499px) and (min-width:1360px) {
    .loginrecruiter {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px;
        margin-left: 5%;
    }

}

@media only screen and (max-width:1360px) and (min-width:1299px) {
    .loginrecruiter {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px;
        margin-left: 6%;
        margin-right: 5px;
    }

}

@media only screen and (max-width:1299px) and (min-width:1200px) {
    .loginrecruiter {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px;
        margin-left: 7%;
        margin-right: 10px;
    }

    .freelance {
        white-space: nowrap;
        overflow: hidden;
        padding: 3px;
    }

    .free {
        margin-top: -20px;
        padding-left: 10px;
    }

}

@media only screen and (min-width:1499px) {
    .loginrecruiter {
        margin-left: 3%;
    }
}

/*forgot demo*/
@media only screen and (max-width:1099px) and (min-width:768px) {
    .forgotcontent {
        height: 100%;
    }
}

@media only screen and (min-width:1100px) {
    .forgotcontent {
        height: 850px;
    }
}

.roletype {
    background-color: $Sky-breeze;
    border: 1px solid $sky-blue;
    border-radius: 10px;
}

.errormsg {
    color: $bright-red;
    font-size: 14px;
    font-weight: 600;
}

.successmsg {
    color: $bright-green;
    font-size: 14px;
    font-weight: 600;
}

.logInImgLeft {
    margin-left: -1%
}

.formContainerMarginTop {
    margin-top: 5%
}

.welcomeBackTextColor {
    color: $neutral-gray
}

.forgot-pwd-margin-right {
    margin-right: 1.2rem,

}

.forgot-pwd-margin-right:hover {
    text-decoration: none;
    color: $muted-purple
}

.login-loader-css {
    display: none;
    padding: 5px 15px
}

.color-dark-gray:hover {
    text-decoration: none;
    color: $dark-gray
}