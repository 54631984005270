.candidate-Profile-Snn-number
{
    position: absolute;
    left: 40px;
    top: 44px;
}
.candidate-Profile-Snn-number-padding-left
{
  padding-left: 74px;
}
.candidate-Profile-loader-padding
{
    padding: 3px 5px
}