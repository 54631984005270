@import '../constants/colors.scss';

.Candidate-Details-Candidate-Id-Text {

    margin-top: 2%;
    margin-left: 3%;
}

.Candidate-Details-Matching-Text {
    margin-top: 25%;
}

.Candidate-Details-Matching-progressbar-width {
    width: 20%
}

.Candidate-Details-loader-padding {
    width: 20%
}

.candidateDetails-Interview:hover {
    color: $primary-white
}

.candidateDetails-Rejected:hover {
    color: $primary-white
}

.candidateDetails-Reject:hover {
    color: $primary-white
}

.candidateDetails-status:hover {
    color: $primary-white
}