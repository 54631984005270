@import 'constants/colors.scss';

@mixin textbox($h) {
    height: $h;
    background: $Whisper-gray;
    border: none;
    border-radius: 8px;
    font-size: 12px;
    padding-left: 0.8rem;
}

// .humbergerIcon {
//     transform: scale(0.8rem);
// }

// .humbergerIcon,
// .replacetitleimg {
//     transition: transform 0.3s ease, margin-left 0.3s ease;
// }

// .replacetitleimg {
//     transition: margin-left 0.3s ease;
//     /* Smooth transition for margin-left */
// }

.resumeforminputfield {
    height: 45px;
    // background: $Soft-gray;
    background: $Cloudy-white;

    // border: 1px solid #C2C2C2;
    border-radius: 8px;
    font-size: 12px;
    padding-left: 0.8rem;
    font-family: 'Roboto';
    border: none,
}

.resumeforminputfield:focus {
    outline: none;

    border: 2px solid $Deep-violet;

    box-shadow: 0 0 5px rgba(91, 79, 183, 0.5);
    // background: $Pale-slate;
    font-family: Roboto;
    // color: $Charcoal;
    background: $Pale-slate;
    color: $Charcoal;
}

.resumeformcalendarfield {
    height: 45px;
    // background: $Soft-gray;
    background: $Pale-slate;

    // border: 1px solid #C2C2C2;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding-left: 0.8rem;
    border: none;
    padding-top: 0.3rem;
}

.resumeformcalendarfield:focus {
    outline: none;

    border: 2px solid $Deep-violet;

    // box-shadow: 0 0 5px rgba(91, 79, 183, 0.5);
    // background: $Pale-slate;
    font-family: Roboto;
    // color: $Charcoal;
    background: $Pale-slate;
    color: $Charcoal;
}

.resumeforminputfield:disabled {
    @include textbox(45px);
}

.resumeforminputfield:disabled::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: $Light-silver;
}

input[type="number"]:disabled::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: $Light-silver;
}

.resumeforminputtext {
    font-weight: 500;
    font-size: 16px;
    color: $charcoal-gray;
}

.resumeformquerytext {
    font-weight: 600;
    font-size: 12px;
    color: $charcoal-gray;

}

.resumeforminputfield::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: $Light-silver;
}

.container1 {
    width: 100%;
    z-index: 1;
}

.progressbar li {
    list-style: none;
    float: left;
    width: 17%;
    position: relative;
    text-align: center;
}

.progressbar .step {
    margin-top: -78px;
}

.progressbar li:before {
    content: "";
    width: 32px;
    height: 32px;
    border: 4px solid $sky-blue;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: $primary-white;
    text-align: center;
    font-weight: bold;
}

.progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: rgba(63, 154, 224, 0.3);
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active+li:after {
    background: $sky-blue;
}

.save {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: $primary-white;
    background: $primary-purple;
    border-radius: 0.3rem;
}

.save:hover {
    color: $primary-white;
}

.save label {
    font-family: roboto;
    font-weight: 600;
    font-size: 18px;
    color: $primary-white;
    margin-bottom: 0px;
}

/*resumebuilder2*/
.newskill {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    color: $Deep-violet;
}

.cancelskill {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    color: $bright-red;
    cursor: pointer;
}

.cancelskill:hover {
    text-decoration: none;
}

.newskill:hover {
    color: $Deep-violet;
}

.cancelskill:hover {
    color: $bright-red;
}

.dropdownforminputfield {
    height: 45px;
    background: $Cloudy-white;
    border: none;
    border-radius: 8px;
}

// .dropdownforminputfield select{
// font-size:12px;
// color:$Graphite-gray;
// }
// .dropdownforminputfield option{
//     font-size:12px;
// color:$Graphite-gray;
// }
select {
    cursor: pointer;
}

@mixin arrow($w, $h, $c, $size) {
    background: url(../icon/downarrow.svg) no-repeat $w $h $Cloudy-white;
    -webkit-appearance: none;
    font-weight: 500;
    color: $c;
    font-size: $size;
}

//navbar
.navbargray {
    width: 25%;
    outline: none;
    @include arrow(100%, 67%, $Dim-Gray, 14px);
}

.inputgray {
    @include arrow(94%, 60%, $Dim-Gray, 14px);

}

.inputgray:disabled {
    @include arrow(94%, 60%, $Dim-Gray, 14px);

}

.inputgray1 {
    @include arrow(94%, 50%, $Graphite-gray, 14px);

}

/*resume builder 2*/
.inputgray2 {
    @include arrow(96%, 50%, $charcoal-gray, 14px);

}

.inputgray3 {
    @include arrow(88%, 50%, $charcoal-gray, 14px);

}

.inputgray2:disabled {
    @include arrow(96%, 50%, $charcoal-gray, 14px);
}

.inputgray3:disabled {
    @include arrow(88%, 50%, $charcoal-gray, 14px);
}

@mixin drop {
    border: none;
    margin-top: 6px;

}

.dropgray:focus {
    background-color: $Cloudy-white;
}

.dropgray1:focus {
    background-color: $Cloudy-white;
}

@media only screen and (min-width:1400px) {
    .dropgray {
        @include arrow(84%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }

    .dropgray1 {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }

    .dropgray:disabled {
        @include arrow(84%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }

    .dropgray1:disabled {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }
}

@media only screen and (min-width:1300px) and (max-width:1400px) {
    .dropgray {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 30px;
    }

    .dropgray1 {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 10px;
    }

    .dropgray:disabled {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 30px;
    }

    .dropgray1:disabled {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 10px;
    }
}

@media only screen and (min-width:1200px) and (max-width:1300px) {
    .dropgray {
        @include arrow(85%, 50%, $Graphite-gray, 12x);
        @include drop;
        padding-left: 23px;
    }

    .dropgray1 {
        @include arrow(88%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 1px;
    }

    .dropgray:disabled {
        @include arrow(85%, 50%, $Graphite-gray, 12x);
        @include drop;
        padding-left: 23px;
    }

    .dropgray1:disabled {
        @include arrow(88%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 1px;
    }
}

@media only screen and (min-width:899px) and (max-width:1200px) {
    .dropgray {
        @include arrow(90%, 50%, $Graphite-gray, 12px);
        @include drop;

    }

    .dropgray1 {
        @include arrow(97%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 0%;
    }

    .dropgray:disabled {
        @include arrow(90%, 50%, $Graphite-gray, 12px);
        @include drop;

    }

    .dropgray1:disabled {
        @include arrow(97%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 0%;
    }
}

@media only screen and (min-width:770px) and (max-width:899px) {
    .dropgray {
        @include arrow(100%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 2px;
    }

    .dropgray1 {
        @include arrow(100%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 5px;
    }

    .dropgray:disabled {
        @include arrow(100%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 2px;
    }

    .dropgray1:disabled {
        @include arrow(100%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 5px;
    }
}

@media only screen and (max-width:770px) {
    .dropgray {
        @include arrow(84%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }

    .dropgray1 {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }

    .dropgray:disabled {
        @include arrow(84%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }

    .dropgray1:disabled {
        @include arrow(85%, 50%, $Graphite-gray, 12px);
        @include drop;
        padding-left: 34px;
    }
}

@media only screen and (max-width: 1350px) and (min-width:1000px) {
    .resumealignment {
        display: block;
    }
}

#lastused::placeholder,
/*RESUME BUILDER3*/
#experience::placeholder,
/*RESUME BUILDER3*/
#valid::placeholder {
    font-weight: 500;
    font-size: 16px;
    color: $Graphite-gray;
}

textarea::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: $Dim-Gray;
}

/*Resume builder6*/
@media only screen and (max-width: 768px) {
    .portfoliocontent {
        margin-top: 15px;
        margin-left: 10px;
    }

    .skypetext {
        margin-left: 10px;
        margin-top: -10px;
    }

}

.resumeradio {
    -webkit-appearance: none;
    width: 20.09px;
    height: 20.09px;
    border: 2px solid $Deep-violet;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}

.resumeradio:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

.resumeradio:checked:before {
    background: $Deep-violet;
}

input[type="month"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.homebtn {
    box-shadow: 0px 0px 31px rgba(145, 96, 209, 0.28);
    border-radius: 18px;
}

.socialinputfield {
    border: none;
    background-color: $Soft-gray;
    font-size: 12px;
}

.socialinputfield:disabled {
    border: none;
    background-color: $Soft-gray;
    font-size: 12px;
}

.socialinputfield:focus {
    background-color: $Soft-gray;
}

.socialinputfield::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: $Light-silver;
}

.errortext {
    font-weight: 500;
    font-size: 12px;
    padding-left: 20%;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    $primary-white-space: nowrap;
}


.step-container {
    display: flex;
    align-items: center;
}

.step-arrow {
    color: #1e1e1e;
    /* Text color */
    padding: 10px 50px;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    // margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
}

.colored-content {
    color: white !important;
    border: 1px solid white;
}

.step-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    width: 0;
    height: 0;
    border-left: 19px solid;
    border-top: 23px solid transparent;
    border-bottom: 22px solid transparent;
    z-index: 5;
}

.bordered-step-arrow::before {
    content: '';
    position: absolute;
    top: -2%;
    right: -21px;
    width: 0;
    height: 0;
    border-left: 20px solid;
    border-top: 24px solid transparent;
    border-bottom: 23.5px solid transparent;
    z-index: 5;
}

.bordered-step-arrow::before {
    border-left-color: black;
}

.colored-content::before {
    border-left-color: white !important;
}

.colored-content {
    &:nth-child(1):after {
        border-left-color: #3D007A !important;
    }

    &:nth-child(2):after {
        border-left-color: #4B0082 !important;
    }

    &:nth-child(3):after {
        border-left-color: #7D26CD !important;
    }

    &:nth-child(4):after {
        border-left-color: #9932CC !important;
    }

    &:nth-child(5):after {
        border-left-color: #BA55D3 !important;
    }
}

.bordered-step-arrow {
    &:nth-child(1):after {
        border-left-color: #F6F4FF;
    }

    &:nth-child(2):after {
        border-left-color: #F6F4FF;
    }

    &:nth-child(3):after {
        border-left-color: #F6F4FF;
    }

    &:nth-child(4):after {
        border-left-color: #F6F4FF;
    }

    &:nth-child(5):after {
        border-left-color: #F6F4FF;
    }
}

// .step-arrow.last {
//     border-radius: 0 3px 3px 0;
//     /* Rounded corners for the last arrow */
//     margin-right: 0;
// }

// .step-arrow.last:after {
//     display: none;
//     /* Remove arrow from the last step */
// }

.step-arrow:first-child {
    border-radius: 3px 0 0 3px;
    /* Rounded corners for the first arrow */
}