.message-container-height {
    height: 83%;
}

.message-container-message-box-css {
    overflow-y: auto;
    height: 500px
}

.message-figureCaption-css {
    transform: scale(0.9);
}

.message-hr-Top-border-width {
    border-top-width: 2px;
}

.message-message-body-container-css {
    overflow-y: auto;
    height: 500px;
    overflow-x: hidden;
}

.message-message-inputbox-and-container {
    position: sticky;
    bottom: 0px
}

.message-message-inputbox-css {
    padding-top: 10px;
    resize: none;
    overflow: hidden;
    backgroundColor: rgba(177, 175, 255, 0.49)
}