.dashboardview-rating-css {
    background: rgba(255, 138, 0, 0.2);
    border-radius: 8px,
}

.dashboardView-email-css {
    word-break: break-all;
    text-align: justify;
    margin-right: 42px;
    margin-left: 50px;
}

.dashboardView-location-css {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
}



// @media (max-width: 1140px) {
//     .sidebar {
//         width: 122px !important;
//     }

//     // .hide {
//     //     display: none;
//     // }

//     // .companylogo {
//     //     position: relative;
//     //     transform: scale(0.5);
//     //     width: 191px;
//     //     left: -2rem;
//     // }
//     // #postjobid {
//     //     transform: translate(117%);
//     // }

//     .navBar-TopBar-bell-icon {
//         transform: translate(239%, 0%);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 71%;
//     }

//     .candidatefirstNameLastName {
//         display: none;
//     }

//     // #postjobid {
//     //     transform: translate(-99%) scale(0.6);
//     // }
// }

// @media (width: 1024px) {

//     .navBar-TopBar-bell-icon {
//         transform: translate(212%, 0%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(20%, 0) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-209%, -30%) scale(1);
//     }
// }

// @media (max-width: 1000px) {
//     .sidebar {
//         width: 122px !important;
//     }

//     // .hide {
//     //     display: none;
//     // }

//     // .companylogo {
//     //     position: relative;
//     //     transform: scale(0.5);
//     //     width: 191px;
//     //     left: -2rem;
//     // }
//     // #postjobid {
//     //     transform: translate(162%);
//     // }

//     .navBar-TopBar-bell-icon {
//         transform: translate(300%, 0%);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 64%;
//         display: inline-block;
//         transform: translateX(5rem);
//     }

//     .candidatefirstNameLastName {
//         display: none;
//     }

//     // #postjobid {
//     //     transform: translate(-99%) scale(0.6);
//     // }
// }

// @media (width: 912px) {

//     .navBar-TopBar-bell-icon {
//         transform: translate(137%, 0%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(20%, 0) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-68%, -30%) scale(1);
//     }
// }

// @media (max-width: 850px) {
//     .sidebar {
//         width: 122px !important;
//     }

//     // .hide {
//     //     display: none;
//     // }

//     // .companylogo {
//     //     position: relative;
//     //     transform: scale(0.5);
//     //     width: 191px;
//     //     left: -2rem;
//     // }
//     // #postjobid {
//     //     transform: translate(91%);
//     // }

//     .navBar-TopBar-bell-icon {
//         transform: translate(123%, 0%);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translateX(2rem);
//     }

//     .candidatefirstNameLastName {
//         display: none;
//     }

//     #postjobid {
//         transform: translate(3%, -19%) scale(1);
//     }
// }

// @media (width: 820px) {



//     .navBar-TopBar-bell-icon {
//         transform: translate(137%, 0%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(20%, 0rem) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-68%, -30%) scale(1);
//     }
// }

// @media (max-width: 767px) {
//     .clientDashcard-container-boxShadow-css {
//         padding: 1rem !important;
//     }

//     .font-size-20px {
//         font-size: 18px !important;
//     }

//     .font-size-32px {
//         font-size: 28px !important;
//     }


//     .navBar-TopBar-bell-icon {
//         transform: translate(837%, -83%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(640%, -6.5rem) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-257%, -15%) scale(1);
//     }
// }

// @media (width: 768px) {



//     .navBar-TopBar-bell-icon {
//         transform: translate(137%, 0%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(20%, 0rem) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-68%, -30%) scale(1);
//     }
// }

// @media (width:683px) {
//     .navBar-TopBar-bell-icon {
//         transform: translate(901%, -89%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(26rem, -6.8rem) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-235%, -10%) scale(0.8);
//     }
// }

// @media (max-width:550px) {
//     .navBar-TopBar-bell-icon {
//         transform: translate(586%, -89%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(19rem, -6.8rem) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-235%, -10%) scale(0.8);
//     }
// }

// @media (max-width:500px) {
//     .navBar-TopBar-bell-icon {
//         transform: translate(480%, -89%) scale(0.8);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(16rem, -6.8rem) scale(0.8);
//     }

//     #postjobid {
//         transform: translate(-149%, -10%) scale(0.8);
//     }


//     // @media (max-width: 450px) {

//     //     .navBar-TopBar-bell-icon {
//     //         transform: translate(383%, -55%) scale(0.8);
//     //         display: inline-block;
//     //     }

//     //     .persononatour {
//     //         margin-left: 61%;
//     //         display: inline-block;
//     //         transform: translate(13rem, -5.5rem) scale(0.8);
//     //     }

//     //     #postjobid {
//     //         transform: translate(-72%, -16%) scale(0.6);
//     //     }
//     // }
// }

// // @media (max-width: 450px) {

// //     .navBar-TopBar-bell-icon {
// //         transform: translate(383%, -55%) scale(0.8);
// //         display: inline-block;
// //     }

// //     .persononatour {
// //         margin-left: 61%;
// //         display: inline-block;
// //         transform: translate(13rem, -5.5rem) scale(0.8);
// //     }

// //     #postjobid {
// //         transform: translate(-72%, -16%) scale(0.6);
// //     }
// // }
// // @media (max-width: 450px) {

// //     .navBar-TopBar-bell-icon {
// //         transform: translate(383%, -55%) scale(0.8);
// //         display: inline-block;
// //     }

// //     .persononatour {
// //         margin-left: 61%;
// //         display: inline-block;
// //         transform: translate(13rem, -5.5rem) scale(0.8);
// //     }

// //     #postjobid {
// //         transform: translate(-72%, -16%) scale(0.6);
// //     }
// // }

// @media (max-width: 450px) {

//     .navBar-TopBar-bell-icon {
//         transform: translate(294%, -96%) scale(0.6);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(281%, -6.9rem) scale(0.6);
//     }

//     #postjobid {
//         transform: translate(-73%, -15%) scale(0.6);
//     }
// }

// @media (width: 430px) {


//     #postjobid {
//         transform: translate(-137%, -15%) scale(0.6);
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(420%, -6.9rem) scale(0.6);
//     }

//     .navBar-TopBar-bell-icon {
//         transform: translate(455%, -96%) scale(0.6);
//         display: inline-block;
//     }
// }

// @media (width: 414px) {


//     #postjobid {
//         transform: translate(-134%, -15%) scale(0.6);
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(378%, -6.9rem) scale(0.6);
//     }

//     .navBar-TopBar-bell-icon {
//         transform: translate(402%, -96%) scale(0.6);
//         display: inline-block;
//     }
// }

// @media (width: 412px) {

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(384%, -6.9rem) scale(0.6);
//     }

//     #postjobid {
//         transform: translate(-131%, -15%) scale(0.6);
//     }

//     .navBar-TopBar-bell-icon {
//         transform: translate(414%, -96%) scale(0.6);
//         display: inline-block;
//     }
// }

// // @media (max-width: 414px) {

// //     .navBar-TopBar-bell-icon {
// //         transform: translate(403%, -66%) scale(0.6);
// //         display: inline-block;
// //     }

// //     .persononatour {
// //         margin-left: 61%;
// //         display: inline-block;
// //         transform: translate(382%, -6rem) scale(0.6);
// //     }
// // }

// @media (width: 375px) {


//     #postjobid {
//         transform: translate(-114%, -15%) scale(0.6);
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(318%, -6.9rem) scale(0.6);
//     }

//     .navBar-TopBar-bell-icon {
//         transform: translate(328%, -96%) scale(0.6);
//         display: inline-block;
//     }
// }

// @media (width: 360px) {
//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(313%, -6.9rem) scale(0.6);
//     }

//     .navBar-TopBar-bell-icon {
//         transform: translate(319%, -96%) scale(0.6);
//         display: inline-block;
//     }

//     #postjobid {
//         transform: translate(-97%, -15%) scale(0.6);
//     }
// }

// @media (width: 390px) {


//     #postjobid {
//         transform: translate(-127%, -15%) scale(0.6);
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(333%, -6.9rem) scale(0.6);
//     }

//     .navBar-TopBar-bell-icon {
//         transform: translate(354%, -96%) scale(0.6);
//         display: inline-block;
//     }
// }

// @media (max-width: 300px) {

//     .navBar-TopBar-bell-icon {
//         transform: translate(194%, -151%) scale(0.6);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(211%, -8.9rem) scale(0.6);
//     }

//     #postjobid {
//         transform: translate(-95%, -19%) scale(0.6);
//     }
// }

// @media (max-width: 250px) {

//     .navBar-TopBar-bell-icon {
//         transform: translate(250%, -66%) scale(0.6);
//         display: inline-block;
//     }

//     .persononatour {
//         margin-left: 61%;
//         display: inline-block;
//         transform: translate(232%, -6rem) scale(0.6);
//     }

//     #postjobid {
//         transform: translate(-99%) scale(0.6);
//     }
// }
@media (max-width: 767px) {
    .candidatefirstNameLastName {
        display: none;
    }

    .postjobbtn {

        border-radius: 20px;

        font-size: 52%;

        margin-left: 0%;
        /* margin-top: 0rem; */
    }

    .navBar-TopBar-dashboard-text {
        display: inline-block;
        position: absolute;
    }
}

/* NavbarTopbar.css */
/* Ensure the hamburger icon is always visible on desktop screens */
// @media (min-width: 1024px) {
//     .css-1235hhr-MuiButtonBase-root-MuiIconButton-root {
//         display: block !important;
//         // position: absolute;
//         // top: 16px;
//         // left: 16px;
//         // z-index: 1201;
//         /* Make sure it’s above other content */
//         color: white;
//         /* Adjust color if needed */
//     }
// }

// /* Hide the hamburger icon on mobile screens */
// @media (max-width: 1023px) {
//     .css-1235hhr-MuiButtonBase-root-MuiIconButton-root {
//         display: block !important;
//     }
// }
