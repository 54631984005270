@import '../constants/colors.scss';

.jobRequirment1-header-design-container-style {
    width: 140px;
}
.jobRequirment1-Requirement-info-border
{
    width: 100%;
    height: 4px;
}
.jobRequirment1-loader-padding
{
     padding: 3px 10px;
}