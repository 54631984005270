@import'../constants/colors.scss';


.candidateSnapExpAddMore-project-summary {
    resize: none;

    border: 1px solid $Pale-silver;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
}