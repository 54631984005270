.candidate-dashcard-box-shadow
{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25)

}
.candidate-dashcard-width-height
{
    border-radius: 20px;
                    width: 4px;
                    height: 95%;
                    margin-left: 5%

}
.candidate-dashcard-icon-padding-radius
{
    border-radius: 35px;
     padding: 9px 9px 13px 9px;

}

