.Requirement-container-switcher-css {
    display: flex;
    align-items: center;
    justify-content: end;
}

.Requirment-container-boxshadow {
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    background-color: white;
}